/** @format */

import React, { useState } from "react";
import styles from "./New.module.scss";
import { API_URL } from "../../../../http";
import moment from "moment";
import ArrowIcon from "../../../../img/IconComponents/ArrowIcon";
import { useTranslation } from "react-i18next";

import tempImg00 from "../../../../img/temp/00.jpg";
import tempImg01 from "../../../../img/temp/01.jpg";
import tempImg02 from "../../../../img/temp/02.jpg";
import tempImg03 from "../../../../img/temp/03.png";
import tempImg04 from "../../../../img/temp/04.jpg";
import tempImg05 from "../../../../img/temp/05.jpg";

import tempImg20 from "../../../../img/temp/temp-new0.png";
import tempImg21 from "../../../../img/temp/temp-new1.jpg";
import tempImg22 from "../../../../img/temp/temp-new2.jpg";
import tempImg23 from "../../../../img/temp/temp-new3.jpg";
import tempImg24 from "../../../../img/temp/temp-new4.jpg";
import tempImg25 from "../../../../img/temp/temp-new5.jpg";
import tempImg26 from "../../../../img/temp/temp-new6.jpg";

import tempImg31 from "../../../../img/temp/temp3-0.png";

const New = ({ item }) => {
  const { t } = useTranslation();
  const { id, title, content, created_at, image_id } = item;
  const [opened, setOpened] = useState(false);
  if (id === "31") {
    return (
      <div className={styles.new} key={id}>
        <div className={styles.new__image}>
          <img src={tempImg31} alt={id} />
        </div>
        <div className={styles.new__body}>
          <p className={styles.new__body_date}>04.02.2025</p>
          <div className={styles.new__body_inner}>
            <h3 className={styles.new__body_title}>
              {t("First cannabis club from Hanover to start cultivation soon")}
            </h3>
            <div
              className={styles.new__body_desc}
              style={{
                WebkitLineClamp: opened ? 100 : 2,
                maxHeight: opened ? "10000px" : "0px",
              }}
            >
              <p>
                {t(
                  "After months of preparation, we are pleased to announce that cultivation will soon begin at the Relzow site. Our first partner to put the DAG-GROW cultivation module into operation is the Cannabis Social Club Hannover Royal e.V.. In February, the responsible members will receive comprehensive instruction and training in the technology so that the club can start cultivation at the beginning of March. The demand for high-quality, legally cultivated cannabis is enormous and CSC Hannover Royal e.V. has been committed to the highest quality standards from the outset."
                )}
              </p>
              <br />
              <b>{t("First strain: Highly potent sativa genetics")}</b>
              <br /> <br />
              <p>
                {t(
                  "The first strain to be grown in the DAG-GROW cultivation module of the CSC Hannover Royal e.V. is a sativa-dominant genetic with a THC content of around 25%. Sativas are characterized by a high proportion of delta-9-tetrahydrocannabinol (THC) and a low cannabidiol (CBD) content. These properties promote an activating effect on the central nervous system and stimulate the mind, which is why they are often preferred for creative activities and daytime consumption."
                )}
              </p>
              <br />
              <p>{t("As soon as the first cuttings are planted, the live cameras are also activated so that the entire process can be transparently documented and followed by the members.")}</p>
              <br />
              <b>{t("Challenges with permits and official requirements")}</b>
              <br /> <br />
              <p>
                {t(
                  "In addition to CSC Hannover Royal e.V., numerous other clubs from various federal states are currently in the approval phase. The application for a cultivation permit is an extremely complex process that can run to several hundred pages, depending on the federal state."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The responsible authorities place high demands on cannabis social clubs, particularly in the areas of health and youth protection, safety concepts and product traceability. It is therefore essential that applications are carefully and comprehensively prepared before they are submitted."
                )}
              </p>

              <br />
              <p>{t("Depending on the competent authority, the processing time can take several months. Once successfully approved, however, the license is valid for seven years and allows the respective club to cultivate cannabis within the legal framework and distribute it to its members.")}</p>
              <br />
              <p>{t("With the imminent commissioning of the DAG-GROW cultivation module in Relzow, CSC Hannover Royal e.V. is one of the first clubs in Germany to take the next step towards a controlled, safe and legal supply for its members.")}</p>
              <br />
              <b>{t("Maximum utilization of the DAG-Grow cultivation module & secure transport to the dispensing point")}</b>
              <br /> <br />
              <p>
                {t(
                  "CSC Hannover Royal e.V. aims to fully utilize the DAG-Grow cultivation module in order to ensure a continuous and needs-based supply for its members. Through optimized cultivation planning and the targeted use of modern cultivation techniques, the module is to be fully utilized so that fresh harvests are regularly produced."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The harvested cannabis is transported monthly to the club's official distribution point in a secure transport. The highest security standards are adhered to in order to ensure a smooth and legally compliant process."
                )}
              </p>
              <br />
              <p>{t("The club's responsible growers are in close contact with the DAG to ensure that all processes run smoothly and that all official requirements are met. All the necessary preparations have been made so that nothing stands in the way of the scheduled cultivation and safe distribution of the cannabis.")}</p>
              <br />
              <b>{t("More information about the CSC Hannover Royal e.V. on:")} <a href="https://csc-hannover-royal.de/" target="_blank" rel="noopener noreferrer">https://csc-hannover-royal.de/</a></b>
              
            </div>
            <div
              className={styles.new__body_link}
              onClick={() => setOpened((prev) => !prev)}
            >
              {opened ? t("Hide Details") : t("View Details")}{" "}
              <ArrowIcon color="#509c5b" rotate={opened ? "-90deg" : "90deg"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (id === "30") {
    return (
      <div className={styles.new} key={id}>
        <div className={styles.new__image}>
          <img src={tempImg20} alt={id} />
        </div>
        <div className={styles.new__body}>
          <p className={styles.new__body_date}>05.12.2024</p>
          <div className={styles.new__body_inner}>
            <h3 className={styles.new__body_title}>
              {t("Presentation of the DAG-GROW cultivation module")}
            </h3>
            <div
              className={styles.new__body_desc}
              style={{
                WebkitLineClamp: opened ? 100 : 2,
                maxHeight: opened ? "10000px" : "0px",
              }}
            >
              <p>
                {t(
                  "On November 30, 2024, the German cultivation company DAG invited partners, cannabis social clubs and interested parties to an exciting event at its location in Murchin. The highlight of the event was the presentation of the DAG-GROW cultivation module, which uses state-of-the-art technology to redefine the future of cannabis cultivation. Among the guests was Senator for Economic Affairs Mr. Frank Schmeichel from Berlin, who gave an inspiring insight into the political landscape for the decriminalization of cannabis."
                )}
              </p>
              <br />
              <b>{t("Insight into the program")}</b>
              <br /> <br />
              <p>
                {t(
                  "After a warm welcome and introduction over coffee in the main building, the event began with a presentation of the company. As well as introducing Deutsche Anbaugesellschaft DAG, the business model was explained in detail, which for many guests was a fascinating insight into the mission and vision of Deutsche Anbaugesellschaft DAG."
                )}
              </p>
              <br />
              <img src={tempImg21} alt="img-21" style={{objectFit: "contain", height: "auto"}}/>
              <br /> <br />
              <p>
                {t(
                  "This was followed by the highlight of the day: a guided tour of the DAG-GROW cultivation module. The participants not only had the opportunity to take a look at the technical equipment and innovative infrastructure, but were also informed about the safety and quality standards that the company sets."
                )}
              </p>
              <br />
              <b>{t("The DAG-GROW add-on module in detail")}</b>
              <br /> <br />
              <p>
                {t(
                  "The DAG-GROW cultivation module impresses with its well thought-out structure and innovative technology. Six specialized rooms are spread over a floor area of 250 m², covering the entire production process from planting to packaging. The heart of the module is the approx. 150 m² cultivation room, where vertical cannabis cultivation takes place. In addition, there are other rooms such as a drying room, a technical room, a changing room and a disinfection sluice, which guarantee the highest standards of hygiene. A processing and packaging room with integrated storage facilities rounds off the modular concept and ensures seamless workflows."
                )}
              </p>
              <br />
              <img src={tempImg22} alt="img-22" style={{objectFit: "contain", height: "auto"}}/>
              <br /> <br />
              <p>
                {t(
                  "Technologically, the DAG-GROW add-on module sets new standards in the industry. A total of 88 LED lamps, each with 780 watts and infrared and ultraviolet radiation, ensure ideal lighting conditions that guarantee optimum plant growth. The complex system for irrigation and nutrient supply was developed together with a specialist from the Netherlands and is perfectly adapted to cannabis cultivation. All parameters can be precisely set and controlled using the central control computer. All information is precisely documented and can be evaluated after each harvest."
                )}
              </p>
              <br />
              <img src={tempImg23} alt="img-23" style={{objectFit: "contain", height: "auto"}}/>
              <img src={tempImg24} alt="img-24" style={{objectFit: "contain", height: "auto"}}/>
              <br /> <br />
              <p>
                {t(
                  "The module works with a closed system that is equipped with a CO2 supply to create ideal growing conditions. The entire module is controlled via the state-of-the-art DECT-GROW cultivation management system, which centrally monitors and regulates all relevant components such as lighting, air conditioning units, dehumidifiers, irrigation, nutrient supply and CO2 supply. This combination of intelligent room design and state-of-the-art technology makes the DAG-GROW cultivation module a pioneering solution for cannabis cultivation."
                )}
              </p>
              <br />
              <b>
                {t("First cannabis club to put the module into operation soon")}
              </b>
              <br /> <br />
              <p>
                {t(
                  "The first DAG-GROW cultivation module will soon be put into operation. This marks a milestone for the German cultivation company DAG, which is launching the first cultivation module for vertical farming with its own software on the German market. Demand is high throughout Germany and the module will be mass-produced in the near future. Deutsche Anbaugesellschaft DAG is thus paving the way for nationwide supply and strengthening its position as a pioneer in the industry."
                )}
              </p>
              <br />
              <img src={tempImg26} alt="img-26" style={{objectFit: "contain", height: "auto"}}/>
              <br /> <br />
              <b>{t("Political insights and networking")}</b>
              <br /> <br />
              <p>
                {t(
                  "Another highlight was the speech by Frank Schmeichel, Senator for Economic Affairs, who shed light on the political perspective on the decriminalization of cannabis. In his speech, he emphasized the importance of innovative companies such as the German cultivation company DAG for the progress of the industry and the goals of decriminalization, health protection and combating the black market. The event also provided an ideal platform for an interactive exchange between more than 40 participants, including cannabis social clubs, partners and interested parties. Over lunch and networking in the showroom, new contacts were made and intensive discussions were held about the future of the industry. Many participants were enthusiastic about the possibilities offered by the DAG-GROW cultivation module and praised the professionalism and foresight of the German cultivation company DAG."
                )}
              </p>
              <br />
              <img src={tempImg25} alt="img-25" style={{objectFit: "contain", height: "auto"}}/>
              <br /> <br />
              <b>{t("A successful event with a positive outlook")}</b>
              <br /> <br />
              <p>
                {t(
                  "The event officially ended at 4 p.m., with some participants taking the opportunity to hold personal discussions and make appointments for individual tours. With a successful mix of technical input, political perspective and innovative technology, the presentation of the DAG-GROW cultivation module was a complete success. With this event, the German cultivation company DAG not only demonstrated its expertise, but also presented a clear vision for the future of cannabis cultivation. With the planned commissioning of the module, the company will have a lasting impact on the industry and set innovative standards."
                )}
              </p>
              <br />
              <p>
                {t(
                  "For further information or if you are interested in a tour, please contact the team at Deutsche Anbaugesellschaft DAG at any time."
                )}
              </p>
            </div>
            <div
              className={styles.new__body_link}
              onClick={() => setOpened((prev) => !prev)}
            >
              {opened ? t("Hide Details") : t("View Details")}{" "}
              <ArrowIcon color="#509c5b" rotate={opened ? "-90deg" : "90deg"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (id === "00") {
    return (
      <div className={styles.new} key={id}>
        <div className={styles.new__image}>
          <img src={tempImg00} alt={id} />
        </div>
        <div className={styles.new__body}>
          <p className={styles.new__body_date}>17.10.2024</p>
          <div className={styles.new__body_inner}>
            <h3 className={styles.new__body_title}>
              {t("Our “DAG-Grow” cultivation module nearing completion")}
            </h3>
            <div
              className={styles.new__body_desc}
              style={{
                WebkitLineClamp: opened ? 100 : 2,
                maxHeight: opened ? "10000px" : "0px",
              }}
            >
              <img src={tempImg00} alt="img-00" />
              <br />
              <p>
                {t(
                  "Today we would like to inform you about the latest developments at Deutsche Anbaugesellschaft DAG GmbH. The first “DAG-Grow” cultivation module is nearing completion in our 640 m² showroom. We have converted a separate hall and set up the module in it so that cannabis clubs can view the module directly on site and spend time in the hall."
                )}
              </p>
              <br />
              <p>
                {t(
                  "With the “DAG-Grow” cultivation module, we have developed a unique solution for small-scale and efficient cannabis cultivation. Covering an area of 250 m², the module comprises six perfectly coordinated rooms: a cultivation room, drying room, technical room, changing room, disinfection sluice and a room for processing and packaging. All work steps, from cultivation to packaging of the end product, are covered within the module. After more than nine months of intensive planning, a solution was created that fully meets the special requirements of cannabis clubs."
                )}
              </p>
              <br />
              <div className={styles.tempNew__images}>
                <img src={tempImg01} alt="img-01" />
                <img src={tempImg02} alt="img-02" />
              </div>
              <br />
              <p>
                {t(
                  "The cultivation module uses a vertical farming system with two levels to make optimum use of the available space. State-of-the-art technologies ensure an efficient and sustainable production process. A specially developed climate control system ensures the ideal temperature and humidity for cultivation, while air purification systems minimize the risk of infection."
                )}
              </p>
              <br />
              <div className={styles.tempNew__floatLeft}>
                <img src={tempImg03} alt="img-03" />
                <p>
                  {t(
                    "For the lighting, 780 watt LED lamps from the DAG-Grow brand are used, which are adapted to the different growth phases of the plants. These lamps offer a broad light spectrum that also includes ultraviolet and infrared radiation. This ensures that the plants receive the right quality of light in every growth phase."
                  )}
                </p>
              </div>
              <br />
              <p>
                {t(
                  "A fully automated high-tech system specially developed for cannabis cultivation is used for optimum irrigation and nutrient supply. All parameters can be precisely set and controlled via a central control unit. The plants need a lot of water and an optimal supply of nutrients is crucial for the plants' growth."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The plants are also supplied with CO2. The use of CO2 (carbon dioxide) is crucial to optimize the growth of cannabis plants, especially under powerful lamps. Enriching the air with CO2 can significantly increase the photosynthesis rate of the plants, resulting in faster growth and higher yields."
                )}
              </p>
              <br />
              <p>
                {t(
                  "All parameters such as the climate, lighting, irrigation, nutrient supply and CO2 supply can be centrally controlled and monitored via our “DECT-Grow” cultivation management system. This is also possible via a tablet or smartphone."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The DAG cultivation module provides the complete infrastructure for cannabis cultivation, including a secure energy supply. The enormous amount of energy required for indoor cannabis cultivation is often underestimated. This already requires a high connected load, which is not available in many buildings."
                )}
              </p>
              <br />
              <p className={styles.tempNew__floatRight}>
                <img src={tempImg04} alt="img-04" />
                {t(
                  "The module has also been comprehensively equipped in terms of security: it has an electronic security system, video surveillance and access controls that are reserved exclusively for members of the growers' associations. The entire site is also monitored around the clock and access is exclusively via a guarded driveway."
                )}
                <br />
                <br />
                {t(
                  "A head grower provides training and further education at the site. He is available around the clock. Later, there will also be networking events and industry meetings."
                )}
                <br />
                <br />
                {t(
                  "With the DAG cultivation module, cannabis clubs can start growing immediately without any major investment or know-how. The module makes it possible to grow up to 300 kg of high-quality cannabis to supply up to 500 members per club."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The completion of the first add-on module will be announced shortly and presented at a press conference with political representatives. We are already in contact with several hundred interested clubs."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The completion of the first add-on module will be announced shortly and presented at a press conference with political representatives. We are already in contact with several hundred interested clubs."
                )}
              </p>
              <br />
              <img src={tempImg05} alt="img-05" />
            </div>
            <div
              className={styles.new__body_link}
              onClick={() => setOpened((prev) => !prev)}
            >
              {opened ? t("Hide Details") : t("View Details")}{" "}
              <ArrowIcon color="#509c5b" rotate={opened ? "-90deg" : "90deg"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.new} key={id}>
      <div className={styles.new__image}>
        <img src={`${API_URL}/v1/local-files/${image_id}`} alt={id} />
      </div>
      <div className={styles.new__body}>
        <p className={styles.new__body_date}>
          {moment(created_at).format("DD.MM.YYYY")}
        </p>
        <div className={styles.new__body_inner}>
          <h3
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className={styles.new__body_title}
          />
          <p
            className={styles.new__body_desc}
            style={{
              WebkitLineClamp: opened ? 100 : 2,
              maxHeight: opened ? "10000px" : "0px",
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div
            className={styles.new__body_link}
            onClick={() => setOpened((prev) => !prev)}
          >
            {opened ? t("Hide Details") : t("View Details")}{" "}
            <ArrowIcon color="#509c5b" rotate={opened ? "-90deg" : "90deg"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
