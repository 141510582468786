import React, { useState, useEffect } from 'react'
import styles from './Main.module.scss'
import SectionTitle from '../../../../components/SectionTitle/SectionTitle'
import empty from '../../../../img/emptyFinances.svg';
import { useTranslation } from 'react-i18next'
import getRequest from '../../../../helpers/getRequest'
import New from '../components/New'

const Main = ({currentLang}) => {
  const [news, setNews] = useState([])
  const { t } = useTranslation()
  useEffect(() => {
    try {
      getRequest('v1/news',null,currentLang.lang)
      .then(async (response) => {
        const data = await response.data
        console.log('news', data.news);
        setNews(data.news)
      })
    } catch (err) {
      console.log(err)
    }
  }, [currentLang])

  return (
    <section className={styles.news}>
      <SectionTitle text={t('Recent news')} />
      {news && news.length ? (
        <div className={styles.news__list}>
          <New item={{id: '31'}} />
          <New item={{id: '30'}} />
          <New item={{id: '00'}} />
          {news.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((newsItem) => {
            return (
              <New item={newsItem}/>
            )
          })}
        </div>
      ) : (
        <div className={styles.news__empty}>
          <img src={empty} alt="empty" />
          <h2>{t('News will be displayed here')}</h2>
        </div>
      )}
    </section>
  )
}

export default Main
